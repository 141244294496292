<template>
  <component
    :is="component"
    :class="{
      'w-4': size === 'small',
    }"
  />
</template>

<script setup lang="ts">
const props = defineProps<{
  name: string
  size?: 'large' | 'medium' | 'small'
}>();

const iconsRaw = import.meta.glob('../icons/*.vue');
const icons = Object.fromEntries(
  Object.entries(iconsRaw).map(([key, component]) => {
    // Convert ComponentName.vue to component-name
    key = key
      .replace(/.*?\/([a-z0-9]+)\.vue/i, '$1')
      .replace(/([a-z])([A-Z0-9])/g, '$1-$2')
      .toLowerCase()
    ;

    return [key, defineAsyncComponent(component)];
  }),
);

const component = computed(() => icons[props.name]);
</script>
